// import request from '@/utils/request'
import axios from 'axios'

const image_url_prefix = 'http://113.45.192.194:9000';
export function uploadImage(file){
  const formData = new FormData();
  // 添加文件到表单中
  formData.append('filedata', file); // 'file'是表单字段的名称，file是要上传的文件对象
  // 发送POST请求
  return axios.post('http://113.45.192.194:63010/media/upload/?group_id=10020', formData, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=WebAppBoundary', // 设置请求头为multipart/form-data
      'Content-Disposition': `form-data; name="filedata"; filename="${encodeURIComponent(file.name)}"`,
      'Content-Type': 'application/octet-stream'
    }
  })
}

export function getImage(id){
  return axios.get('http://113.45.192.194:63010/media/one',{
    params:{
      id
    }
  })
}

export function uploadImageByBase64(base64){
  const file = base64ToFile(base64,'pasteImage.png');
  return uploadImage(file);
}

function base64ToFile(base64, fileName) {
  let arr = base64.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
 
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

export {image_url_prefix};
