import store from "..";

const state = {
  food_society_history: [{
    content: '学会成立',
    timestamp: '2018-04-12 20:46',
    color: '#26935a'
  }, {
    content: '学会成立',
    timestamp: '2018-04-03 20:46',
    color: '#26935a'
  }, {
    content: '学会成立',
    timestamp: '2018-04-03 20:46',
    color: '#26935a'
  }, {
    content: '学会成立',
    timestamp: '2018-04-03 20:46',
    color: '#26935a'
  }],
}

const mutations = {
  QUERY_FOOD_SOCIETY_HISTORY: (state,list) => {
    state.food_society_history = list;
  },

}

const actions = {
  queryHistory({commit}){
    commit('QUERY_FOOD_SOCIETY_HISTORY',list);
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}