<template>
  <div id="app">
    <home></home>
  </div>
</template>
<script>
import Home from './views/home';
export default {
  name:"App",
  components:{
    Home
  }
}
</script>
<style>
:root{
  --menu-color: #26935a;
  --menu-active-color:#4aa676;
  --menu-border-color: #4aa676;
  --menu-item-active-color:#26935a;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: block;
  background: var(--vs-theme-layout);
}
::-webkit-scrollbar-thumb {
  background: #6bcba5;
  border-radius: 5px;
}
body{
  margin: 0;
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  background-repeat: no-repeat;
  /* background-color: rgb(222, 241, 226); */
  /* background: -moz-linear-gradient(310deg, #6bcba5 0%, #fff 50%);
  background: -webkit-linear-gradient(310deg, #6bcba5 0%, #fff 50%);
  background: linear-gradient(180deg, #6bcba5 0%, #fff 50%); */
}
.el-carousel__button{
  width: 10px !important;
}
img{
  max-width: 100%;
}
.cursorclick{
  cursor: pointer;
}
</style>
