import { getCompanies, createCompany, deleteCompany, updateCompany,getAllCompanies } from "@/api/company"
import { getImage,image_url_prefix } from '@/api/media'
import store from "..";

const state = {
  companies:[],
  all_companies:[]
}

const mutations = {

  QUERY_COMPANY_LIST: (state,list) => {
    state.companies = list;
  },
  QUERY_ALL_COMPANY_LIST:(state,list) => {
    state.all_companies = list;
  }
}

const actions = {
  queryCompanyList({commit}){
    getCompanies(this.state.user.userid).then(res => {
      const list = res.data;
      const pros = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const logo = element.logo;
        if(logo){
          const info = logo;
          const array = info.split(";");
          const id = array[0];
          pros.push(getImage(id))
        }
      }
      Promise.all(pros).then(res=>{
        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          if(element.status != 200){
            continue;
          }
          let data = element.data;
          if(data.code != 20000){
            continue;
          }
          data = data.data;
          for (let i = 0; i < list.length; i++) {
            const logo = list[i].logo;
            if(logo){
              const info = logo;
              const array = info.split(";");
              const id = array[0];
              if(id == data.id){
                list[i].logo = image_url_prefix + data.url;
                break;
              }
            }
          }
        }
        commit('QUERY_COMPANY_LIST',list);
      })
    })
  },
  queryCreateCompany({commit},com_info){
    com_info.userId = this.state.user.userid;
    createCompany(com_info).then(res =>{
      store.dispatch('company/queryCompanyList')
    })
  },
  queryCompanyDelete({commit},com_id){
    deleteCompany(com_id).then(res=>{
      store.dispatch('company/queryCompanyList')
    })
  },
  queryCompanyUpdate({commit},info){
    updateCompany(info[0],info[1]).then(res=>{
      store.dispatch('company/queryCompanyList')
    })
  },
  queryAllCompanies({commit}){
    return getAllCompanies().then(res=>{
      const list = res.data;
      const pros = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const logo = element.logo;
        if(logo){
          const info = logo;
          const array = info.split(";");
          const id = array[0];
          pros.push(getImage(id))
        }
      }
      return Promise.all(pros).then(res=>{
        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          if(element.status != 200){
            continue;
          }
          let data = element.data;
          if(data.code != 20000){
            continue;
          }
          data = data.data;
          for (let i = 0; i < list.length; i++) {
            const logo = list[i].logo;
            if(logo){
              const info = logo;
              const array = info.split(";");
              const id = array[0];
              if(id == data.id){
                list[i].logo = image_url_prefix + data.url;
                break;
              }
            }
          }
        }
        commit('QUERY_ALL_COMPANY_LIST',list);
      })
    })
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}