import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/root',
  },
  {
    path:'/root',
    name:'root',
    component:() => import('@/views/root')
  },
  {
    path:'/register',
    name:'register',
    component: () => import('@/views/user/userregister.vue')
  },
  {
    path:'/login',
    name:'login',
    component: () => import('@/views/user/userlogin.vue')
  },
  { path: '/food-society/introduce', name: '食品学会/学会介绍', component: () => import('@/views/food_society/introduce') },
  { path: '/food-society/org', name: '食品学会/组织结构', component: () => import('@/views/food_society/org') },
  { path: '/food-society/leaders', name: '食品学会/现任领导', component: () => import('@/views/food_society/leaders') },
  { path: '/food-society/development', name: '食品学会/学会动态', component: () => import('@/views/food_society/development') },
  { path: '/food-society/advantage', name: '食品学会/会员优势', component: () => import('@/views/food_society/advantage') },
  { path: '/food-society/join-us', name: '食品学会/成为会员', component: () => import('@/views/food_society/join_us') },
  { path: '/enterprise-information/map', name: '企业资讯/企业地图', component: () => import('@/views/enterprise_information/map') },
  { path: '/enterprise-information/news', name: '企业资讯/新闻资讯', component: () => import('@/views/enterprise_information/news') },
  { path: '/enterprise-information/policy-elucidation', name: '企业资讯/政策解读', component: () => import('@/views/enterprise_information/policy_elucidation') },
  { path: '/enterprise-information/social-work', name: '企业资讯/社会工作', component: () => import('@/views/enterprise_information/social_work') },
  { path: '/enterprise-information/group', name: '企业资讯/企业组群', component: () => import('@/views/enterprise_information/group') },
  { path: '/enterprise-information/influence', name: '企业资讯/区域影响', component: () => import('@/views/enterprise_information/influence') },
  { path: '/enterprise-service/demand', name: '企业服务/企业需求', component: () => import('@/views/enterprise_service/demand') },
  { path: '/enterprise-service/infomation', name: '企业服务/信息发布', component: () => import('@/views/enterprise_service/infomation') },
  { path: '/enterprise-service/brand-improve', name: '企业服务/品牌提升', component: () => import('@/views/enterprise_service/brand_improve') },
  { path: '/enterprise-service/cooperative-result', name: '企业服务/合作成果', component: () => import('@/views/enterprise_service/cooperative_result') },
  { path: '/per-work/service-list', name: '产学研工作/服务列表', component: () => import('@/views/per_work/service_list') },
  { path: '/per-work/cooperative-project', name: '产学研工作/合作项目', component: () => import('@/views/per_work/cooperative_project') },
  { path: '/per-work/research-institutes', name: '产学研工作/科研机构', component: () => import('@/views/per_work/research_institutes') },
  { path: '/per-work/research-cooperation', name: '产学研工作/科研合作', component: () => import('@/views/per_work/research_cooperation') },
  { path: '/per-work/cooperative-result', name: '产学研工作/合作成果', component: () => import('@/views/per_work/cooperative_result') },
  { path: '/per-work/technology-transfer', name: '产学研工作/技术转移', component: () => import('@/views/per_work/technology_transfer') },
  { path: '/per-work/academic-exchange', name: '产学研工作/学术交流', component: () => import('@/views/per_work/academic_exchange') },
  { path: '/preferred-products/storage', name: '产品优选/产品优选库', component: () => import('@/views/preferred_products/storage') },
  { path: '/preferred-products/quality-control', name: '产品优选/质量管理', component: () => import('@/views/preferred_products/quality_control') },
  { path: '/preferred-products/interim-control', name: '产品优选/临期控制', component: () => import('@/views/preferred_products/interim_control') },
  { path: '/preferred-products/popularize', name: '产品优选/多渠道推广', component: () => import('@/views/preferred_products/popularize') },
  { path: '/preferred-products/stars', name: '产品优选/明星企业', component: () => import('@/views/preferred_products/stars') },
  { path: '/industry-db/enterprise-db', name: '产业资料库/企业库', component: () => import('@/views/industry_db/enterprise_db') },
  { path: '/industry-db/professor-db', name: '产业资料库/专家库', component: () => import('@/views/industry_db/professor_db') },
  { path: '/industry-db/achievement-db', name: '产业资料库/成果库', component: () => import('@/views/industry_db/achievement_db') },
  { path: '/industry-db/product-db', name: '产业资料库/产品库', component: () => import('@/views/industry_db/product_db') },
  { path: '/dgut/gov-orientation', name: '东莞理工/政府导向', component: () => import('@/views/dgut/gov_orientation') },
  { path: '/dgut/ut-job', name: '东莞理工/学校工作', component: () => import('@/views/dgut/ut_job') },
  { path: '/dgut/social-service', name: '东莞理工/社会服务', component: () => import('@/views/dgut/social_service') },
  { path: '/news/:id', name: '文章详情', component: () => import('@/views/news') },
  { path: '/company/:id', name: '企业详情', component: () => import('@/views/industry_db/enterprise_db/comshow.vue') },
  { path: '/wechat/company/db', name: '企业库', component: () => import('@/views/wechat/company') },
  { path: '/wechat/product/db', name: '产品库', component: () => import('@/views/wechat/product') },
]

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}


const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) =>{
  // to: 即将进入的路由对象
  // from: 当前导航正要离开的路由对象
  // next: 调用该方法，才能进入下一个钩子
  // console.log(to);
  store.dispatch('routename/changeRouteName',to.path);
  next()
})

export default router
