<template>
  <div class="root">
    <!-- <cheader></cheader> -->
    <navbar v-if="curRouteName == '/root'"></navbar>
    <template v-else-if="curRouteName.indexOf('wechat') > -1"></template>
    <slheader v-else></slheader>
    <router-view></router-view>
    <backtop></backtop>
  </div>
</template>

<script>
import backtop from '@/components/backtop/backtop.vue';
import slheader from '@/components/layouts/header/slheader.vue'

import navbar from '../../components/layouts/navbar/navbar.vue';
import { mapGetters } from 'vuex'

// import navbar from '@/components/layouts/navbar3.vue';
// import Cheader from './header.vue';
export default {
  components:{
    backtop,
    slheader,
    navbar,
    // Cheader
  },
  computed:{
    ...mapGetters([
          'curRouteName',
          'navitems',
        ]),
  }
 
}
</script>

<style scoped>
.root{
  
}
</style>