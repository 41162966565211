const state = {
    currentRouteName:'home'
}

const mutations = {
    CHANE_ROUTE_NAME: (state,name) => {
        // 获取categories
        state.currentRouteName = name;
    }
}

const actions = {
    changeRouteName({ commit }, name) {
        commit('CHANE_ROUTE_NAME',name);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  
