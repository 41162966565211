<template>
  <div class="g-nav">
    <div class="back"></div>
    <div class="logo-box">
      <el-image style="width: 70px;" :src="logo_"></el-image>
      <h2>食品产业数字化服务平台</h2>
    </div>
    <div class="login-btn">
      <span class="login-btn-text" @click="login">登录</span>
      <span class="login-btn-text" @click="register">注册</span>
    </div>
    <ul>
      <li><a href="#">首页</a></li>
      <li v-for="item in navitems" :key="item.path">
        <t-dropdown v-if="item.children && item.children.length > 0" 
          :min-column-width="88" 
          @click="clickHandler" 
          placement="bottom"
          >
          <a href="javascript:void(0)">{{item.name}}</a>
          <t-dropdown-menu >
            <t-dropdown-item 
            v-for="subitem in item.children" 
            :key="item.path + subitem.path" 
            :value="item.path + subitem.path">
            <p class="dropdown-item">
              {{ subitem.name }}
            </p>
          </t-dropdown-item>
          </t-dropdown-menu>
        </t-dropdown>
        <a v-else> {{ item.name }} </a>
      </li>
    </ul>
    <menucheckbox @change="onChange"></menucheckbox>
  </div>
</template>
 
<script>
import { mapGetters } from 'vuex';
import logo_ from "@/assets/logo/logo_.png"
import menucheckbox from "@/components/checkbox/menu1.vue"
export default {
  components:{
    menucheckbox
  },
  data(){
    return {
      logo_
    }
  },
  computed:{
    ...mapGetters([
        'curRouteName',
        'navitems',
      ]),
  },
  mounted(){

  },
  methods:{
    clickHandler(val){
      const url = val.value;
      this.$router.push(url);
    },
    onChange(val){
      console.log(val);
    },
    login(){
      window.open("http://www.bsomanage.com:9528/#/dashboard");
      // this.$router.push('/login');
    },
    register(){
      this.$router.push('/register');
    },
  }
}
</script>

<style scoped>
.back{
  position: absolute;
  width: 100vw;
  height: 100px;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}
h2{
  color:#fff;
  margin-left: 10px;
  z-index: 1001;
}
.g-nav{
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;  
}
.logo-box{
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
}

.g-nav li {
  position: relative;
  margin-left: 0.32rem;
  list-style: outside none;
  width: 100px;
  margin-inline-start: 0;
}
ul{
  display: flex;
  margin-top: 40px;
  margin-right: 40px;
}

a:link {
  text-decoration: none;
  outline: none;
}
a{
  outline: none;
  color: #fff;
  font-weight: bold;
}
.dropdown-item{
  text-align: center;
  line-height: 30px;
  margin: 0;
}
.login-btn{
  position: absolute;
  right: 100px;
  top: 5px;
}
.login-btn-text{
  color: #fff;
  font-size: 12px;
  margin: 5px;
}
.login-btn-text:hover{
  color: gainsboro;
  cursor: pointer;
}
</style>