import request from '@/utils/request.js'
/**
 * 根据用户id获取公司列表
 * @param {number} uid 
 * @returns 
 */
export function getCompanies(uid){
  // return request.get('/company/comlist',{params:{uid}})
  return request({
    url:'/company/comlist',
    method:'get',
    params:{uid}
  })
}

export function getAllCompanies(){
  return request({
    url:'/company/allcom',
    method:'get'
  })
}

export function createCompany(cominfo){
  return request(
    {
      url:'/company/create',
      method:'post',
      data:cominfo
    }
  )
}

export function updateCompany(comid,cominfo){
  return request(
    {
      url:'/company/update?comid='+comid,
      method:'post',
      data:cominfo,
    }
  )
}

export function deleteCompany(com_id){
  return request({
    url:'/company/del',
    method:'get',
    params:{
      comid:com_id
    }
  })
}
/**
 * 获取单个公司实体信息
 * @param {number} comid 
 * @returns 
 */
export function getCompany(comid){
  return request({
    url:'/company/cominfo',
    method:'get',
    params:{
      comid
    }
  })
}

'/company/desc/listall'
export function createCompanyDesc(dto){
  return request({
    url:'/company/desc/create',
    method:'post',
    data:dto
  })
}

export function updateCompanyDesc(dto){
  return request({
    url:'/company/desc/update',
    method:'post',
    data:dto
  })
}

export function deleteCompanyDesc(descId){
  return request({
    url:'/company/desc/delete',
    method:'get',
    params:{
      desc:descId
    }
  })
}
export function getCompanyDescList(comid){
  return request({
    url:'/company/desc/list',
    method:'get',
    params:{
      comid
    }
  })
}

export function getCompanyDesc(descId){
  return request({
    url:'/company/desc/one',
    method:'get',
    params:{
      desc:descId
    }
  })
}
export function updateCompanyDescShow(comid,descid){
  return request({
    url:'/company/desc/updateshow',
    method:'get',
    params:{
      comid,
      descid
    }
  })
}
export function getCompanyDescShow(comid){
  return request({
    url:'/company/desc/getshow',
    method:'get',
    params:{
      comid
    }
  })
}