const getters = {
  menus: state => state.menus.menus,
  food_society_menus: state => state.menus.food_society_menus,
  food_society_history: state => state.foodsociety.food_society_history,
  curRouteName:state => state.routename.currentRouteName,
  my_companies: state => state.company.companies,
  all_companies: state => state.company.all_companies,
  avatar: state => state.user.avatar,
  nickName: state => state.user.nickName,
  userId: state => state.user.userid,
  username: state => state.user.username,
  roles: state => state.user.roles,
  navitems: state => state.menus.navitems,
  introduction: state => state.user.introduction
}
export default getters
