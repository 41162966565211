const state = {
  menus:[
    {
      path:'/root',
      name:"首页",
      children:[]
    },
    {
      path:'/spxh',
      name:"食品学会",
      children:[
      ]
    },
    {
      path:'/guanpin',
      name:"“莞品”品牌",
      children:[
        {
          path:"/guanpin-introduce",
          name:"品牌简介",
        },
        {
          path:"/guanpin-yanjiu-introduce",
          name:"研究院简介",
        },
        {
          path:"/guanpin-func",
          name:"功能作用",
        },
        {
          path:"/guanpin-partner",
          name:"共建单位",
        },
      ]
    },
    {
      path:'/zljk',
      name:"智联健康",
      children:[
        {
          path:"/zljk-qyk",
          name:"企业基本信息库",
        },
        {
          path:"/zljk-zjk",
          name:"专家库",
        },
        {
          path:"/zljk-qydt",
          name:"企业地图",
        },
      ]
    },
    {
      path:'/keputiandi',
      name:"科普天地",
      children:[
      ]
    },
  ],
  food_society_menus:[
    {
      id:1,
      path:'/food-society/introduce',
      name:"学会介绍",
    },
    {
      id:2,
      path:'/food-society/org',
      name:"组织结构",
    },
    {
      id:3,
      path:'/food-society/leaders',
      name:"现任领导",
    },
    {
      id:4,
      path:'/food-society/development',
      name:"学会动态",
    },
    {
      id:5,
      path:'/food-society/advantage',
      name:"会员优势",
    },
  ],
  navitems:[
    {
      path:'/food-society',
      name:"食品学会",
      children:[
        {
          path:'/introduce',
          name:"学会介绍",
        },
        {
          path:'/org',
          name:"组织结构",
        },
        {
          path:'/leaders',
          name:"现任领导",
        },
        {
          path:'/development',
          name:"学会动态",
        },
        {
          path:'/advantage',
          name:"会员优势",
        },
        {
          path:'/join-us',
          name:"成为会员",
        },
      ]
    },
    {
      path:'/enterprise-information',
      name:"企业资讯",
      children:[
        {
          path:'/map',
          name:"企业地图",
        },
        {
          path:'/news',
          name:"新闻资讯",
        },
        {
          path:'/policy-elucidation',
          name:"政策解读",
        },
        {
          path:'/social-work',
          name:"社会工作",
        },
        {
          path:'/group',
          name:"企业组群",
        },
        {
          path:'/influence',
          name:"区域影响",
        },
      ]
    },
    {
      path:'/enterprise-service',
      name:"企业服务",
      children:[
        {
          path:'/demand',
          name:"企业需求",
        },
        {
          path:'/infomation',
          name:"信息发布",
        },
        {
          path:'/brand-improve',
          name:"品牌提升",
        },
        {
          path:'/cooperative-result',
          name:"合作成果 ",
        },
      ]
    },
    {
      path:'/per-work',
      name:"产学研工作",
      children:[
        {
          path:'/service-list',
          name:"服务列表",
        },
        {
          path:'/cooperative-project',
          name:"合作项目",
        },
        {
          path:'/research-institutes',
          name:"科研机构",
        },
        {
          path:'/research-cooperation',
          name:"科研合作",
        },
        {
          path:'/cooperative-result',
          name:"合作成果",
        },
        {
          path:'/technology-transfer',
          name:"技术转移",
        },
        {
          path:'/academic-exchange',
          name:"学术交流",
        },
      ]
    },
    {
      path:'/preferred-products',
      name:"产品优选",
      children:[
        {
          path:'/storage',
          name:"产品优选库",
        },
        {
          path:'/quality-control',
          name:"质量管理",
        },
        {
          path:'/interim-control',
          name:"临期控制",
        },
        {
          path:'/popularize',
          name:"多渠道推广",
        },
        {
          path:'/stars',
          name:"明星企业",
        },
      ]
    },
    {
      path:'/industry-db',
      name:"产业资料库",
      children:[
        {
          path:'/enterprise-db',
          name:"企业库",
        },
        {
          path:'/professor-db',
          name:"专家库",
        },
        {
          path:'/achievement-db',
          name:"成果库",
        },
        {
          path:'/product-db',
          name:"产品库",
        },
      ]
    },
    {
      path:'/dgut',
      name:"东莞理工",
      children:[
        {
          path:'/gov-orientation',
          name:"政府导向",
        },
        {
          path:'/ut-job',
          name:"学校工作",
        },
        {
          path:'/social-service',
          name:"社会服务",
        },
      ]
    },
  ],
}

const mutations = {
  QUERY_MENUS: (state,list) => {
      // 获取categories
      state.menus = list;
  }
}

const actions = {
  queryMenus({ commit }) {
      commit('QUERY_MENUS');
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

