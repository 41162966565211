<template>
  <button-vue class="backtop"
      v-show="show"
      icon
      :active="active == 0"
      @click="onClick"
    >
    </button-vue>
</template>
<script>
import buttonVue from './button.vue';
export default {
  components:{
   buttonVue
  },
  data(){
      return {
          active:1,
          show:false,
      }
  },
  mounted(){
      window.onscroll = this.onScroll;
  },
  methods:{
      onScroll(){
          var height = document.documentElement.scrollTop || document.body.scrollTop
          if(height >= 300) {
              this.show = true;
          } else {
              this.show = false;
          }
      },
      onClick(){
          this.active = 0;
          this.goTop();
      },
      goTop(){
          window.scrollTo({
              top:0,
              behavior:'smooth'
          })
      }
  }
}
</script>
<style scoped>
.backtop{
  position: fixed;
  right: 50px;
  bottom: 50px;
}
</style>
