import { login,getInfo,logout,createUser } from '@/api/user'
import { MessageBox, Message } from 'element-ui'
import store from "..";
import router from '@/router';
const state = {
  userid:0,
  username:'',
  avatar:'',
  nickName:'',
  roles:[],
  introduction:'',
}

const mutations = {
  USER_INFO: (state,info) => {
      state.username = info.name;
      state.userid = info.uid;
      state.avatar = info.avatar;
      state.nickName = info.nickName;
      state.roles = info.roles;
      state.introduction = info.introduction;
  },
  CLEAR_INFO:(state) => {
    state.username = '';
    state.userid = '';
    state.avatar = '';
    state.nickName = '';
    state.roles = [];
    state.introduction = '';
  }
}

const actions = {
  createUser({ commit }, user) {
    createUser(user).then(res=>{
      const code = res.code;
      if(code == 40004){
        Message({
          message: "用户已存在，请更换用户名",
          type: 'error',
          duration: 5 * 1000
        })
        return;
      }
      Message({
        message: "注册成功",
        type: 'success',
        duration: 5 * 1000
      })
      store.dispatch('user/login',{
        username:user.name,
        password:user.pwd,
      });
    })
  },
  getUserInfo({commit},uid){
    getInfo(uid).then(res=>{
      if(res.code == 40003){
        Message({
          message: "无效用户ID",
          type: 'error',
          duration: 5 * 1000
        })
        return;
      }
      commit('USER_INFO',res.data);
      router.push('/')
    })
  },
  logout({ commit }){
    logout().then(res=>{
      commit('CLEAR_INFO');
    })
  },
  login({ commit },user){
    login(user).then(res=>{
      const code = res.code;
      if(code == 40002){
        Message({
          message: "用户不存在",
          type: 'error',
          duration: 5 * 1000
        })
        return;
      }else if(code == 40001){
        Message({
          message: "用户名或密码错误",
          type: 'error',
          duration: 5 * 1000
        })
        return;
      }
      store.dispatch('user/getUserInfo',res.data);
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

