<template>
  <div class="g-nav">
    <div class="back">
      <div class="logo-box">
      <el-image style="width: 70px;" :src="logo_"></el-image>
      <h2>食品产业数字化服务平台</h2>
      </div>
      <div class="login-btn">
        <span class="login-btn-text" @click="login">登录</span>
        <span class="login-btn-text" @click="register">注册</span>
      </div>
      <menucheckbox :value="activeSidebar" @input="onChange"></menucheckbox>
    </div>
    <div class="split-line">
      <h3 v-if="$route.name != ''" style="color:#fff;margin-left: 20px;">{{ $route.name }}</h3>
    </div>

    <vs-sidebar
      right
      absolute
      square
      v-model="curActive"
      :open.sync="activeSidebar"
      style="position:fixed !important;"
    >
      <template #logo>
        <!-- ...img logo -->
        <div class="logo"></div>
      </template>
      <vs-sidebar-item 
          @click.native="onClickMenuItem('/')"
        >
        <template #icon>
          <div class="space-icon"></div>
        </template>
         首页
        </vs-sidebar-item>
      <vs-sidebar-group v-for="item in navitems" :key="item.path">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <div class="space-icon"></div>
            </template>
            {{item.name}}
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item 
          v-for="subitem in item.children" 
          :key="item.path + subitem.path" 
          :id="item.path + subitem.path"
          @click.native="onClickMenuItem(item.path + subitem.path)"
        >
        <template #icon>
          <div class="space-icon"></div>
        </template>
        {{ subitem.name }}
        </vs-sidebar-item>
      </vs-sidebar-group>
    </vs-sidebar>
  </div>
</template>
 
<script>
import { mapGetters } from 'vuex';
import logo_ from "@/assets/logo/logo_.png"
import menucheckbox from "@/components/checkbox/menu.vue"
export default {
  components:{
    menucheckbox
  },
  data(){
    return {
      logo_,
      activeSidebar: false,
      curActive:'',
    }
  },
  computed:{
    ...mapGetters([
        'curRouteName',
        'navitems',
      ]),
  },
  watch:{
    curRouteName(){
      this.curActive = this.curRouteName;
    }
  },
  mounted(){

  },
  methods:{
    clickHandler(val){

    },
    onClickMenuItem(path){
      this.$router.push(path);
    },
    onChange(val){
      this.activeSidebar = val == 'on' ? true:false;
    },
    login(){
      window.open("http://www.bsomanage.com:9528/#/dashboard");
      // this.$router.push('/login');
    },
    register(){
      this.$router.push('/register');
    },
  }
}
</script>

<style scoped>
.split-line {
  width: 100%;
  height: 40px;
  background-color: var(--menu-color);
  margin: 0;
  display: flex;
  align-items: center;
}
.back{
  width: 100vw;
  height: 90px;
  display:flex;
}
h2{
  color:#000;
  margin-left: 10px;
  z-index: 1001;
}
.g-nav{
  z-index: 1000;
  margin-bottom: 20px;
}
.logo-box{
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
}

.login-btn{
  position: absolute;
  right: 100px;
  top: 5px;
}
.login-btn-text{
  color: #000;
  font-size: 12px;
  margin: 5px;
}
.login-btn-text:hover{
  color: gainsboro;
  cursor: pointer;
}
.space-icon{
  width: 10px;
  height: 10px;
  background-color: transparent;
}
.logo{
  width: 100px;
}
</style>