import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import menus from './modules/menus'
import routename from './modules/routename'
import user from './modules/user'
import company from './modules/company'
import foodsociety from './modules/foodsociety'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    menus,
    routename,
    company,
    user,
    foodsociety
  },
})
