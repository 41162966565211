import axios from 'axios';
import { MessageBox, Message } from 'element-ui'

const request = axios.create({
  baseURL:'http://113.45.192.194:63010',
  // baseURL:'http://localhost:60090',
  // baseURL:'http://localhost:63040',
  timeout:60000
})

request.interceptors.response.use(
  response => {
    const res = response.data;
    if(!res) return null;
    // if(res.code != 20000){
    //   Message({
    //     message:res.data || "Error",
    //     type:'error',
    //     duration:5 * 1000
    //   })
    // }

    return res;
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default request;