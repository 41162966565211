import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './vuesax.css'
import 'element-tiptap/lib/index.css';
import { ElementTiptapPlugin } from 'element-tiptap';
import TDesign from 'tdesign-vue';
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';

Vue.config.productionTip = false
Vue.use(ElementTiptapPlugin, { /* 插件配置项 */ });
Vue.use(Vuesax, {
    colors: {
      primary:'#26935a',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }
  })
Vue.use(TDesign);
Vue.use(ElementUI, {
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
